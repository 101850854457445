import React from 'react'
import { useState, useEffect, useContext } from "react";
import { Navigate } from 'react-router-dom';
import { getRandomIntInclusive} from "./Utils"
import { GlobalStateContext } from "../pages/GlobalStateContext"

//const apiUrl = "HTTPS://siamapi.kiwijones.com"
const Transactions = ({userId}) => {

    const {apiHostUrl, setApiHostUrl} = useContext(GlobalStateContext);
    const [data, setData] = useState([]);
    const [loadingT, setLoadingT] = useState(true);
    
    useEffect(() => {


      let randomT = getRandomIntInclusive(1000,3000)
      setTimeout(() => {
      

        const myHeaders = new Headers();
        myHeaders.append("Accept", "text/plain");
    
        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
        };
    
        var url = apiHostUrl + "/user/ListTransactions/"+ userId + ""
        
        fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => {
            
            console.log(result)

            setLoadingT(false)
            
            setData(JSON.parse(result))

        }
        )
        .catch((error) => console.error(error));
      }, randomT);
    }, []);
  return (

    <>
    {loadingT ? <img src="../Small_spinner.svg" /> : ""}
    <h3>Recent Transactions</h3>
      
      <div>
        <table className="table ">
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Points</th>
            <th>Before</th>
            <th>After</th>
          </tr>

          {data.map((item) => (
            <tr>
              <td>{item.date}</td>
              <td>{item.amount}</td>
              <td>{item.points}</td>
              <td>{item.before}</td>
              <td>{item.after}</td>
            </tr>
          ))}
        </table>
      </div>
    
    
    </>
  )
}

export default Transactions