import React, { useEffect, useState,useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalStateContext } from "../pages/GlobalStateContext"
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

//const apiUrl = "HTTPS://siamapi.azurewebsites.net"

function LoginForm() {
   
  const {apiHostUrl, setApiHostUrl} = useContext(GlobalStateContext);
  const { userGuid, setUserGuid } = useContext(GlobalStateContext);
  const {isLoggedIn, setIsloggedIn} = useContext(GlobalStateContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [navAway, setNavAway] = useState(false);

  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(false);

  const myHeaders = new Headers();

  const [showPassword, setShowPassword] = useState(false);



  myHeaders.append("Accept", "text/plain");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
   
  };

  useEffect(() => {
    
    //setting the username & password for next visit
    //setPassword(localStorage.getItem("password"))
    setUsername(localStorage.getItem("username"))

  }, []); // Empty dependency array means this effect runs once when the component mounts


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

 


  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    // Here you can perform authentication logic
    //console.log("Username:", username);
    //console.log("Password:", password);

    // Reset form fields
    setUsername("");
    setPassword("");

    setUserGuid("hello");

    let url =
    apiHostUrl + "/user/Login/" +
      username +
      "/" +
      password +
      "";

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {

        //console.log(result)

        if (result.includes('Not')) {

          toast.warn("invalid login");

          setNavAway(true);

        } else {
          

          localStorage.setItem("username",username)
          //localStorage.setItem("password",password)
          toast.success("Login OK");

          setAuth(true);

          setIsloggedIn(true)
          
          sessionStorage.setItem("userId", result);
         
          setUserGuid(result);

        }

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);

        toast.warning(error);
      });
  };

  return (
    <>
      {navAway ? <Navigate to="/" /> : ""}

      {auth ? (
        <Navigate to="/mydetails" />
      ) : (
        <div className="container_2">
          {loading ? <img src="../Small_Spinner.svg" /> : ""}

          <form onSubmit={handleSubmit}>
            <div>
              <h3>RM Loyalty</h3>
              {/* <label htmlFor="username">Username:</label> */}
              <input
                style={{ margin: "3px" }}
                className="custom-input"
                type="number"
                id="username"
                value={username}
                placeholder="Phone Number"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              
            </div>
            <div  style={{ position: "relative", display: "inline-block" }}>
              {/* <label htmlFor="password">Password:</label> */}
              <input
                style={{ margin: "3px", paddingRight: "30px" }}
                className="custom-input"
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
                
              />
              <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}

                </span>
            </div>
            <button
              className="primary-button"
              style={{ margin: "3px" }}
              type="submit"
            >
              Login
            </button>

            <div>
              <Link to="/forgot">
                <button className="secondary-button" style={{ margin: "3px" }}>
                  Reset password
                </button>
              </Link>
            </div>

            <div></div>
          </form>
        </div>
      )}
    </>
  );
}

export default LoginForm;
